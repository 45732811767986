<template>
  <div class="showWindowCenter" style="z-index: 99999;">
    <v-container>
      <v-row align="center" justify="center">
       <v-col>
          <v-alert
            prominent
            :type="noticeType"
            v-model="alert"
            :dismissible="dismissible"
          >
            <v-row >
              <v-col class="grow">{{ result["message"] }}</v-col>
              <v-col class="shrink"> </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ["result"],
  data() {
    return {
      alert: true,
    };
  },
  computed: {
    dismissible() {
      let close = true;
      if (typeof this.result.dismissible != "undefined") {
        close = this.result.dismissible;
      }
      return close;
    },
    noticeType() {
      let noticeType = "error";
      if (typeof this.result.noticeType != "undefined") {
        noticeType = this.result.noticeType;
      }
      return noticeType;
    },
  },
  created() {
    if (this.result.message != "you do not have permission to view this page") {
      if (
        typeof this.result.redirect != "undefined" ||
        typeof this.result.delayedRedirect != "undefined"
      ) {
        let delay = 3000;
        if (typeof this.result.delay != "undefined") {
          delay = this.result.delay;
        }
        let caller = this;
        setTimeout(() => {
          this.alert = false;
          if (caller.$route.path == caller.result.redirect) {
            caller.$router.go();
          } else {
            caller.$router.push(caller.result.redirect).catch(() => {
              caller.$router.go();
            });
          }
        }, delay);
      }
    }
  },
};
</script>
